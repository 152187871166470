import React from 'react'
import styled from 'styled-components'

import { GridList, Logo } from '@te-digi/styleguide'
import tokens from '@te-digi/styleguide-tokens'

import tyomarkkinatoriSvg from '@te-digi/styleguide/assets/tyomarkkinatori.svg'
import tyomarkkinatoriEnSvg from '@te-digi/styleguide/assets/tyomarkkinatori-en.svg'
import tyomarkkinatoriSvSvg from '@te-digi/styleguide/assets/tyomarkkinatori-sv.svg'
import tyomarkkinatoriPrimarySvg from '@te-digi/styleguide/assets/tyomarkkinatori-primary.svg'
import tyomarkkinatoriEnPrimarySvg from '@te-digi/styleguide/assets/tyomarkkinatori-en-primary.svg'
import tyomarkkinatoriSvPrimarySvg from '@te-digi/styleguide/assets/tyomarkkinatori-sv-primary.svg'
import tyomarkkinatoriAsiantuntijaSvg from '@te-digi/styleguide/assets/tyomarkkinatori-asiantuntija.svg'

import { Playground } from '../../components/Playground'
import { Content } from '../../components/Content'
import { Section } from '../../components/Section'

const StyledWrapper = styled.div<{ dark?: boolean }>`
  background-color: ${props => props.dark && tokens.color.dark};
  border-radius: ${tokens.radius.md};
  padding: ${tokens.space.md};
  width: 100%;

  img {
    display: block;
    height: 4rem;
    margin: 0 auto;
    max-width: 100%;
  }
`

const StyledWrapper2 = styled.div`
  padding: ${tokens.space.md};
  width: 100%;

  img {
    display: block;
    height: 4rem;
    margin: 0 auto;
    max-width: 100%;
  }
`
// HUOM! lead-teksti määritellään pages.ts -tiedostossa
const Page = () => (
  <Content
    heading="Logo"
    components={[{ component: Logo }]}
  >
    <Section>
      <Playground
        isDark
        WrapperComponent={StyledWrapper2}
      >
        <Logo
          alt="Työmarkkinatori"
          src={tyomarkkinatoriSvg}
        />
      </Playground>
      <Playground
        format="html"
        isDark
        WrapperComponent={StyledWrapper2}
      >
        <img
          alt="Työmarkkinatori"
          src={tyomarkkinatoriSvg}
        />
      </Playground>
    </Section>
    <Section title="Valkoinen tummalle taustalle">
      <GridList lg={3}>
        <StyledWrapper dark>
          <Logo
            alt="Työmarkkinatori"
            src={tyomarkkinatoriSvg}
          />
        </StyledWrapper>
        <StyledWrapper dark>
          <Logo
            alt="Job Market"
            src={tyomarkkinatoriEnSvg}
          />
        </StyledWrapper>
        <StyledWrapper dark>
          <Logo
            alt="Jobbmarknad"
            src={tyomarkkinatoriSvSvg}
          />
        </StyledWrapper>
      </GridList>
    </Section>
    <Section title="Tumma valkoiselle taustalle">
      <GridList lg={3}>
        <StyledWrapper>
          <Logo
            alt="Työmarkkinatori"
            src={tyomarkkinatoriPrimarySvg}
          />
        </StyledWrapper>
        <StyledWrapper>
          <Logo
            alt="Job Market"
            src={tyomarkkinatoriEnPrimarySvg}
          />
        </StyledWrapper>
        <StyledWrapper>
          <Logo
            alt="Jobbmarknad"
            src={tyomarkkinatoriSvPrimarySvg}
          />
        </StyledWrapper>
      </GridList>
    </Section>
    <Section title="Asiantuntijan Työmarkkinatori">
      <GridList lg={3}>
        <StyledWrapper dark>
          <Logo
            alt="Asiantuntijan Työmarkkinatori"
            src={tyomarkkinatoriAsiantuntijaSvg}
          />
        </StyledWrapper>
      </GridList>
    </Section>
  </Content>
)

export default Page
